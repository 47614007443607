<template>
	<el-dialog :title="$t('i18nn_786280a019b8a1b7')" append-to-body :close-on-click-modal="false" :visible.sync="dialogFile" width="1200px"
		>
		<div class="">
			<!--本页切换列表-->
			<div>
				<div class="tableCon" v-loading="loading_load" :element-loading-text="$t('47df8be257c59dde')">
					<div class="tableConTable">
						<!-- <HyQuillEditor ref="myTextEditor2" :value="leaveMessage" @editorChange="onEditorChange" class=""></HyQuillEditor> -->
						<div v-html="leaveMessage"></div>
					</div>
				</div>
			</div>
		</div>
		<div slot="footer" class="dialog-footer">
			<!-- <el-button type="primary" @click="submitAction()">{{ $t('i18nn_ad4345dbaabe1479') }}</el-button> -->
			<el-button type="primary" plain @click="dialogFile = false">{{ $t('4e9fc68608c60999') }}</el-button>
		</div>
	</el-dialog>
</template>
<script>
	// import LgsFedexTrackTem from '@/components/StorageCenter/ExpressSheet/LgsFedexTrackTem.vue';
	// import LgsUpsTrackSimTem from '@/components/StorageCenter/ExpressSheet/LgsUpsTrackSimTem.vue';
	
	// import HyQuillEditor from '@/components/Common/HyQuillEditor.vue';
	
	export default {
		// name: 'HomeConfFrame',
		props: {
			openTime: {
				// default: function() {
				//   return '';
				// },
				// type: String
			},
			row: {}
		},
		components: {
			// LgsFedexTrackTem,
			// LgsUpsTrackSimTem
			// HyQuillEditor
		},
		data() {
			return {
				dialogFile: false,
				loading_load: false,

				//表格数据
				//loading,表格数据
				loading: false,
				leaveMessage:"",
				
				//表格数据
				// fedexData: {},
				// upsData:{},
				// dataOpenTime:"",
				//分页数据
				// pagination: {
				// 	current_page: 1,
				// 	page_sizes: [5, 20, 50, 100, 200, 300],
				// 	page_size: 200,
				// 	total: 0
				// },

				// selectOption: {
				// 	wh_no: [],
				// },
				//查询，排序方式
				filterData: {
					orderBy: 'id_', //排序字段
					sortAsc: 'desc', //desc降序，asc升序

					// orderNumber: '',
					"id": null, //"数据ID",
					// "carrier": null, //"物流公司",
					// "tracking": null, //this.$t('i18nn_4531476fa35570f0')
				}
			};
		},
		watch: {
			openTime: function(newVal, oldVal) {
				console.log('openTime');
				this.initData();
				//初始化
				this.dialogFile = true;
			}
		},

		activated() {
			console.log(' activated');
		},
		//创建时
		created() {
			console.log(' created');
		},
		//编译挂载前
		mounted() {
			// this.getDicData();
			// this.getWhNoListData();
		},
		methods: {
			goBack() {

			},
			
			initData() {
				this.filterData.id = this.row.id;
				this.leaveMessage = "";
				this.getDetData();
			},
			
			// 富文本
			onEditorChange(html) {
			  // this.form.contentStr = html;
				this.leaveMessage = html;
			},
			// //请求数据
			getDetData() {

				this.loading_load = true;
				this.$http
					.get(this.$urlConfig.WhQARecMoreText+"?keyId="+this.filterData.id, {})
					.then(({
						data
					}) => {
						this.loading_load = false;
						if (200 == data.code && data.data) {
							this.leaveMessage = data.data;
						} else {
							this.$message.warning(data.msg ? data.msg : this.$t('hytxs0000029'));
						}
					})
					.catch(error => {
						console.log(error);
						this.$message.error(this.$t('hytxs0000030'));
						this.loading_load = false;
					});
			},
			// submitAction(){
			// 	if(!this.leaveMessage){
			// 		this.$message.warning(this.$t('i18nn_efbfa769d113b9fb'))
			// 		return;
			// 	}
			// 	let filterData = {
			// 		"id": this.filterData.id, //"数据ID",
			// 		"comment": this.leaveMessage, //"",
			// 	};
				
			// 	this.loading_load = true;
			// 	this.$http
			// 		.put(this.$urlConfig.WhSendGoodsCommentSave, filterData)
			// 		.then(({
			// 			data
			// 		}) => {
			// 			this.loading_load = false;
			// 			if (200 == data.code) {
			// 				this.$message.success(this.$t('i18nn_b93f7c8c4e2346f8'));
			// 				this.dialogFile = false;
			// 			} else {
			// 				this.$message.warning(data.msg ? data.msg : this.$t('i18nn_a7d2e953195a5588'));
			// 			}
			// 		})
			// 		.catch(error => {
			// 			console.log(error);
			// 			this.$message.error("提交失败！");
			// 			this.loading_load = false;
			// 		});
			// },
			//选择行
			// handleCurrentChange(row, event, column) {
			// 	// this.$refs.multipleTable.toggleRowSelection(row, true); //点击选中
			// },
			// //多选
			// handleSelectionChange(val) {
			// 	console.log(val);
			// 	// this.multipleSelection = val;
			// },


		}
	};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">

</style>
